<template>
  <div class="customer-in-progress">
    <div class="customer-in-progress__statistic bg-white">
      <b-container fluid class="p-0">
        <b-row>
          <b-col
            cols="3"
            class="customer-in-progress__statistic__total customer-in-progress__statistic__col d-flex justify-content-center align-items-center"
          >
            <span class="customer-in-progress__statistic__total__text mr-2"
              >Tổng</span
            >
            <span class="customer-in-progress__statistic__total__value"
              >168</span
            >
          </b-col>
          <b-col
            cols="3"
            class="customer-in-progress__statistic__figure customer-in-progress__statistic__col p-0"
          >
            <div
              class="d-flex justify-content-center align-items-center h-50 customer-in-progress__statistic__figure__row"
            >
              <b-col cols="9" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__text"
                  >Mới vào 2 tuần qua</span
                >
              </b-col>
              <b-col cols="3" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__value"
                  >168</span
                >
              </b-col>
            </div>
            <div
              class="d-flex justify-content-center align-items-center h-50 customer-in-progress__statistic__figure__row"
            >
              <b-col cols="9" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__text"
                  >Sắp kết thúc 2 tuần tới</span
                >
              </b-col>
              <b-col cols="3" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__value"
                  >168</span
                >
              </b-col>
            </div>
          </b-col>
          <b-col
            cols="3"
            class="customer-in-progress__statistic__figure customer-in-progress__statistic__col p-0"
          >
            <div
              class="d-flex justify-content-center align-items-center h-50 customer-in-progress__statistic__figure__row"
            >
              <b-col cols="9" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__text"
                  >Đường huyết thấp</span
                >
              </b-col>
              <b-col cols="3" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__value"
                  >24</span
                >
              </b-col>
            </div>
            <div
              class="d-flex justify-content-center align-items-center h-50 customer-in-progress__statistic__figure__row"
            >
              <b-col cols="9" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__text"
                  >Đường huyết cao</span
                >
              </b-col>
              <b-col cols="3" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__value"
                  >16</span
                >
              </b-col>
            </div>
          </b-col>
          <b-col
            cols="3"
            class="customer-in-progress__statistic__figure customer-in-progress__statistic__col p-0"
          >
            <div
              class="d-flex justify-content-center align-items-center h-50 customer-in-progress__statistic__figure__row"
            >
              <b-col cols="9" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__text"
                  >Hoàn thành bài học &gt; 50%</span
                >
              </b-col>
              <b-col cols="3" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__value"
                  >168</span
                >
              </b-col>
            </div>
            <div
              class="d-flex justify-content-center align-items-center h-50 customer-in-progress__statistic__figure__row"
            >
              <b-col cols="9" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__text"
                  >Hoàn thành bài học &lt; 50%</span
                >
              </b-col>
              <b-col cols="3" class="ellipsis">
                <span class="customer-in-progress__statistic__figure__value"
                  >356</span
                >
              </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <basic-input
      class="customer-in-progress__search"
      label="Tên khách hàng"
      placeholder="Nhập tên khách hàng"
    />
    <template-table
      :column="column"
      :data="data"
      :paging="paging"
      :tableAction="false"
      :selectAction="false"
      :searchAction="false"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomerListInProgress',
  data() {
    return {
      column: [
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'message',
          label: 'Tin nhắn',
          sortable: false,
        },
        {
          key: 'schedule',
          label: 'Lịch trình',
          sortable: false,
        },
        {
          key: 'nextDate',
          label: 'Buổi hẹn tiếp theo',
          sortable: false,
        },
        {
          key: 'activies',
          label: 'Hoạt động gần đây',
          sortable: false,
        },
        {
          key: 'progress',
          label: 'Trạng thái',
          sortable: false,
        },
        {
          key: 'note',
          label: 'Ghi chú',
          sortable: false,
        },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
};
</script>

<style lang="scss">
.customer-in-progress {
  .customer-in-progress__statistic {
    margin-bottom: 56px;

    .customer-in-progress__statistic__col {
      height: 100px;
    }

    .customer-in-progress__statistic__total {
      background-color: #def2f1;

      .customer-in-progress__statistic__total__text {
        color: #01645a;
        font-size: 16px;
      }

      .customer-in-progress__statistic__total__value {
        color: #01645a;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .customer-in-progress__statistic__figure {
      .customer-in-progress__statistic__figure__row {
        border: 1px solid #f2f3f7;
        padding: 0 56px;

        .customer-in-progress__statistic__figure__text {
          font-size: 16px;
        }

        .customer-in-progress__statistic__figure__value {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  .customer-in-progress__search {
    width: 350px;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
